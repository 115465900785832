
import {mapGetters} from 'vuex';
import elsomLogo from '~/static/elsom.png';

export default {
  name: 'footer-block',
  data() {
    return {
      elsomLogo,
      year: (new Date()).getFullYear(),
    };
  },
  computed: {
    ...mapGetters('sulu', ['menu']),
  },
};
