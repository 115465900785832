import Vue from 'vue';

export const state = () => ({
  ua: '',
  mobile: false,
  offline: false,
  initialOffline: false,
  theme: 'light',
  pushToken: '',
  pushRegistered: false,
});

export const strict = false;

export const getters = {
  isMobile(state) {
    return state.mobile;
  },
  isOffline(state) {
    return state.offline;
  },
  isInitialOffline(state) {
    return state.initialOffline;
  },
  currentTheme (state) {
    return state.theme;
  },
  pushToken (state) {
    return state.pushToken;
  },
  isPushRegistered (state) {
    return state.pushRegistered;
  },
};


export const mutations = {
  setUa(state, value) {
    state.ua = value;
  },
  setMobile(state, value) {
    state.mobile = value;
  },
  setOffline: (state, value) => {
    state.offline = value;
  },
  setInitialOffline: (state, value) => {
    state.initialOffline = value;
  },
  setTheme (state, value) {
    state.theme = value;
  },
  setPushToken (state, value) {
    state.pushToken = value;
  },
  setPushRegistered (state, value) {
    state.pushRegistered = value;
  },
};

async function initLang(app) {
  const currentLang = app.$cookiz.get('defaultLanguage') || 'ru';
  Vue.prototype.$locale = currentLang;
  await app.i18n.setLocale(currentLang);
}

export const actions = {
  async nuxtServerInit ({commit, dispatch}, {app, req, isDev}) {
    initLang(app);
    if (process.env.type !== 'agent') await dispatch('sulu/initSuluSender', {req, isDev, requests: ['getMenu']});

    const currentTheme = app.$cookiz.get('currentTheme') || 'light';
    commit('setTheme', currentTheme);
  },
};
